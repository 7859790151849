@charset "UTF-8";
/* stylelint-disable no-descending-specificity */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.sended-form {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  direction: ltr;
  background-color: rgba(16, 88, 80, 0.86);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}
.sended-form .sended-form-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  width: 29.4791666667vw;
  height: 13.6458333333vw;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  background-color: #fff;
  font-family: "NarkisBlock-Medium", sans-serif;
  font-size: 2.2395833333vw;
  line-height: 0.93;
}
@media only screen and (max-width: 767px) {
  .sended-form .sended-form-wrapper {
    width: 90%;
    height: 69.8666666667vw;
    max-width: 150.9333333333vw;
    font-size: 11.4666666667vw;
    line-height: 0.93;
  }
}
.sended-form .sended-form-wrapper .close {
  width: 99%;
  display: flex;
  justify-content: flex-start;
  margin-top: 0.2604166667vw;
  margin-right: 0.2604166667vw;
}
@media only screen and (max-width: 767px) {
  .sended-form .sended-form-wrapper .close {
    margin-top: 1.3333333333vw;
    margin-right: 0.5333333333vw;
  }
}
.sended-form .sended-form-wrapper .close img {
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .sended-form .sended-form-wrapper .close img {
    width: 9.0666666667vw;
  }
}
.sended-form .sended-form-wrapper span {
  margin-top: 9%;
  align-self: center;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sended-form .sended-form-wrapper span {
    margin-top: 17.3333333333vw;
  }
}
.sended-form .sended-form-wrapper span span {
  font-family: "NarkisBlock-Medium", sans-serif;
}
.sended-form[class*=show] {
  opacity: 1;
  visibility: visible;
}