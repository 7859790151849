@charset "UTF-8";
/* stylelint-disable order/order */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.text-field {
  position: relative;
  display: block;
  width: 100%;
  text-align: right;
  border-bottom: 1px solid #000;
}
.text-field input {
  display: block;
  width: 100%;
  height: 32px;
  padding: 0 7px 7px 10px;
  line-height: 0vw;
  background-color: #fff;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 500;
  color: #000;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .text-field input {
    font-size: 12px;
  }
}
@media only screen and (max-width: 767px) {
  .text-field input {
    height: 9.6vw;
    padding: 0 1.8666666667vw 0 3.2vw;
    line-height: 10.6666666667vw;
    font-size: 4vw;
    font-weight: 300;
  }
}
.text-field input.inline {
  background-color: transparent;
  border-radius: 0;
  border-bottom: 1px solid #707070;
}
.text-field input:-webkit-autofill,
.text-field input:-webkit-autofill:hover,
.text-field input:-webkit-autofill:focus,
.text-field input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 50px white inset !important;
}
.text-field .mobile-phone {
  direction: ltr;
  text-align: right;
}
.text-field .text-label {
  position: relative;
  padding-right: 2px;
  padding-bottom: 10px;
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  color: #000;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .text-field .text-label {
    font-size: 12px;
  }
}
@media only screen and (max-width: 767px) {
  .text-field .text-label {
    padding-right: 0.5333333333vw;
    padding-bottom: 2.6666666667vw;
    font-size: 4.8vw;
    line-height: 6.1333333333vw;
  }
}
.text-field .text-label.is-required {
  padding-right: 7px;
}
@media only screen and (max-width: 767px) {
  .text-field .text-label.is-required {
    padding-right: 1.8666666667vw;
  }
}
.text-field .text-label.is-required::after {
  content: "*";
  position: absolute;
  top: 0;
  right: 0;
  font-size: 15px;
  color: #000;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .text-field .text-label.is-required::after {
    font-size: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .text-field .text-label.is-required::after {
    top: -0.2666666667vw;
    right: 0;
    font-size: 4.8vw;
  }
}
.text-field textarea {
  display: block;
  width: 100%;
  padding: 10px 10px;
  border-radius: 0;
  background-color: #fff;
  border: solid 1px #000;
  border-bottom: none;
  outline: none;
  font-size: 18px;
  line-height: 21px;
  color: #000;
  resize: none;
}
@media only screen and (max-width: 767px) {
  .text-field textarea {
    padding: 3.2vw 3.2vw;
    line-height: 5.0666666667vw;
    font-size: 4.5333333333vw;
  }
}
.text-field textarea.small {
  height: 104px;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .text-field textarea.small {
    height: 16vw;
  }
}
@media only screen and (max-width: 767px) {
  .text-field textarea.small {
    height: 27.7333333333vw;
  }
}
.text-field textarea.big {
  height: 205px;
}
@media only screen and (max-width: 767px) {
  .text-field textarea.big {
    height: 54.6666666667vw;
  }
}
.text-field .placeholder {
  position: absolute;
  top: 3px;
  right: 7px;
  font-weight: 500;
  font-size: 15px;
  text-align: right;
  line-height: 1.3;
  color: #787878;
}
@media only screen and (max-width: 767px) {
  .text-field .placeholder {
    top: 2.1333333333vw;
    right: 1.8666666667vw;
    padding-left: 3.2vw;
    font-size: 4vw;
    font-weight: 300;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .text-field .placeholder {
    right: 0.625vw;
    font-size: 12px;
    line-height: 1;
  }
}
.text-field .placeholder span {
  font-size: 15px;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .text-field .placeholder span {
    font-size: 2.9333333333vw;
  }
}
@media only screen and (max-width: 767px) {
  .text-field .placeholder span {
    font-size: 7.2vw;
    letter-spacing: 0.1653333333vw;
  }
}
.text-field .placeholder.hide {
  display: none;
}
.text-field .placeholder.for-textarea {
  top: 7px;
  right: 16px;
}
@media only screen and (max-width: 767px) {
  .text-field .placeholder.for-textarea {
    top: 3.4666666667vw;
    right: 4.2666666667vw;
  }
}