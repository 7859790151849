@charset "UTF-8";
/* stylelint-disable no-descending-specificity */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
@value transitionIn: 1000ms;
@value transitionOut: 1000ms;
.intro {
  overflow-x: hidden;
}
.intro.appear, .intro.enter {
  opacity: 0;
}
.intro.appearing, .intro.entering {
  opacity: 1;
  transition: opacity;
  transition-duration: transitionIn;
}
.intro.exit {
  opacity: 1;
}
.intro.exiting {
  opacity: 0;
  transition: opacity;
  transition-duration: transitionOut;
}
.intro a {
  text-decoration: none;
  color: #31302a;
}
.intro .contentInner {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 2.0833333333vw;
}
@media only screen and (min-aspect-ratio: 4/2) {
  .intro .contentInner {
    position: static;
    justify-content: normal;
    overflow-y: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .intro .contentInner {
    position: static;
    padding-top: 2.6666666667vw;
  }
}
@media only screen and (max-width: 767px) {
  .intro .contentInner {
    position: static;
    padding-top: 5.6vw;
  }
}
.intro .content {
  position: relative;
  display: flex;
  padding: 0.703125vw 0 1.875vw;
  flex-direction: column;
  justify-content: flex-end;
  overflow-y: auto;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 767px) {
  .intro .content {
    display: block;
    height: auto;
    padding: 73.8666666667vw 0 7.4666666667vw;
    background-position: 50% 50%;
    background-size: cover;
  }
}
.intro .content .logo-wrapper {
  width: 6.390625vw;
  margin: 0 auto;
}
@media only screen and (max-width: 767px) {
  .intro .content .logo-wrapper {
    width: 32.8vw;
    padding-bottom: 2.6666666667vw;
  }
}
.intro .content .logo-wrapper img {
  display: block;
  width: 100%;
}
.intro .content .title-wrapper {
  width: 43.9583333333vw;
  padding-bottom: 0.625vw;
  margin: -1.0416666667vw auto 0;
  margin-top: 0.5208333333vw;
}
@media only screen and (max-width: 767px) {
  .intro .content .title-wrapper {
    width: 153.0666666667vw;
    padding-bottom: 5.3333333333vw;
    margin: 0 auto;
  }
}
.intro .content .title-wrapper img {
  display: block;
  width: 100%;
}
.intro .content .text1 {
  width: 38.0208333333vw;
  margin: 0 auto;
}
@media only screen and (max-width: 767px) {
  .intro .content .text1 {
    width: 160vw;
  }
}
.intro .content .text1 p {
  padding-bottom: 1.40625vw;
  font-size: 1.3020833333vw;
  line-height: 1.40625vw;
  color: #fff;
  text-align: center;
  font-family: "NarkisBlock-Light", sans-serif;
}
@media only screen and (max-width: 767px) {
  .intro .content .text1 p {
    padding-bottom: 7.4666666667vw;
    font-size: 7.7333333333vw;
    line-height: 8vw;
  }
}
.intro .content .subtitle-wrapper {
  width: 8.0208333333vw;
  margin: -0.3645833333vw auto 0;
}
@media only screen and (max-width: 767px) {
  .intro .content .subtitle-wrapper {
    width: 46.4vw;
    padding-top: 6.6666666667vw;
    margin: 0 auto;
  }
}
.intro .content .subtitle-wrapper img {
  display: block;
  width: 100%;
}
.intro .content .text2 {
  width: 38.0208333333vw;
  margin: -0.5208333333vw auto 0;
}
@media only screen and (max-width: 767px) {
  .intro .content .text2 {
    width: 157.0666666667vw;
    margin: 0 auto;
  }
}
.intro .content .text2 p {
  font-size: 1.3020833333vw;
  line-height: 1.40625vw;
  color: #fff;
  text-align: center;
  font-family: "NarkisBlock-Light", sans-serif;
}
@media only screen and (max-width: 767px) {
  .intro .content .text2 p {
    padding-bottom: 7.4666666667vw;
    font-size: 7.7333333333vw;
    line-height: 8vw;
  }
}