@charset "UTF-8";
/* stylelint-disable no-descending-specificity */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.form {
  width: 57.2395833333vw;
  padding: 4.9479166667vw 0 3.0729166667vw;
  margin: 0 auto;
}
@media only screen and (max-width: 767px) {
  .form {
    width: 100%;
    padding: 9.7333333333vw 15.2vw 14.6666666667vw 16vw;
  }
}
.form .title {
  padding-bottom: 2.0833333333vw;
}
@media only screen and (max-width: 767px) {
  .form .title {
    padding-bottom: 10.6666666667vw;
  }
}
.form .title.with-paddings {
  padding: 2.6041666667vw 0 3.2291666667vw;
}
@media only screen and (max-width: 767px) {
  .form .title.with-paddings {
    padding: 13.3333333333vw 0 9.3333333333vw;
  }
}
.form .title.with-top-padding {
  padding-top: 1.71875vw;
}
@media only screen and (max-width: 767px) {
  .form .title.with-top-padding {
    padding-top: 11.4666666667vw;
  }
}
.form .title h3 {
  font-size: 1.3541666667vw;
  line-height: 1.7708333333vw;
  color: #084466;
  font-family: "NarkisBlock-Heavy", sans-serif;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form .title h3 {
    font-size: 1.875vw;
    line-height: 2.3958333333vw;
  }
}
@media only screen and (max-width: 767px) {
  .form .title h3 {
    font-size: 8.5333333333vw;
    line-height: 11.2vw;
  }
}
.form .title p {
  font-size: 0.9375vw;
  line-height: 1.25vw;
  color: #000;
  font-family: "NarkisBlock-Bold", sans-serif;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form .title p {
    font-size: 1.1458333333vw;
    line-height: 1.4583333333vw;
  }
}
@media only screen and (max-width: 767px) {
  .form .title p {
    padding-top: 2.6666666667vw;
    font-size: 7.2vw;
    line-height: 9.0666666667vw;
  }
}
.form .additional-text {
  padding-top: 1.5625vw;
  padding-bottom: 2.1875vw;
}
@media only screen and (max-width: 767px) {
  .form .additional-text {
    padding-top: 1.8666666667vw;
    padding-bottom: 16.5333333333vw;
  }
}
.form .additional-text p {
  font-size: 0.9375vw;
  line-height: 1.25vw;
  color: #000;
  font-family: "NarkisBlock-Bold", sans-serif;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form .additional-text p {
    font-size: 1.1458333333vw;
    line-height: 1.4583333333vw;
  }
}
@media only screen and (max-width: 767px) {
  .form .additional-text p {
    font-size: 7.2vw;
    line-height: 9.0666666667vw;
  }
}
.form .radio-btns-group-title {
  padding-bottom: 1.1458333333vw;
}
@media only screen and (max-width: 767px) {
  .form .radio-btns-group-title {
    padding-bottom: 12.5333333333vw;
  }
}
.form .radio-btns-group-title p {
  font-size: 0.78125vw;
  line-height: 0.9895833333vw;
  font-family: "NarkisBlock-Light", sans-serif;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form .radio-btns-group-title p {
    font-size: 0.9895833333vw;
    line-height: 1.1979166667vw;
  }
}
@media only screen and (max-width: 767px) {
  .form .radio-btns-group-title p {
    font-size: 7.2vw;
    line-height: 9.6vw;
  }
}
.form .radio-btns-group-title p:first-child {
  padding-bottom: 0.2604166667vw;
  font-size: 0.9375vw;
  line-height: 0.9895833333vw;
  font-family: "NarkisBlock-Bold", sans-serif;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form .radio-btns-group-title p:first-child {
    font-size: 1.1458333333vw;
    line-height: 1.1979166667vw;
  }
}
@media only screen and (max-width: 767px) {
  .form .radio-btns-group-title p:first-child {
    padding-bottom: 0.8vw;
    font-size: 7.2vw;
    line-height: 9.6vw;
  }
}

.form-row.with-checkboxes {
  padding: 13px 0 6.5px;
}
@media only screen and (max-width: 767px) {
  .form-row.with-checkboxes {
    padding: 0 0 5.2vw;
  }
}
.form-row.with-button {
  position: relative;
  display: flex;
  margin-top: 2.1875vw;
  margin-bottom: 0.5729166667vw;
  justify-content: center;
  align-items: center;
  border-bottom: none;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form-row.with-button {
    margin-top: 4vw;
  }
}
@media only screen and (max-width: 767px) {
  .form-row.with-button {
    margin-top: 14.6666666667vw;
    margin-bottom: 0;
  }
}
.form-row.with-button .submit-btn {
  width: 27.0833333333vw;
  height: 3.6458333333vw;
  padding: 0 0 0.3125vw 0;
  font-size: 2.03125vw;
  line-height: 1.31;
  letter-spacing: 0.27px;
  border: 0;
  font-family: "NarkisBlock-Bold", sans-serif;
  background-color: #ff212c;
  color: #fff;
  text-align: center;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form-row.with-button .submit-btn {
    width: 27.0833333333vw;
    height: 3.6458333333vw;
    font-size: 2.03125vw;
    line-height: 1.31;
  }
}
@media only screen and (max-width: 767px) {
  .form-row.with-button .submit-btn {
    width: 100%;
    height: 18.6666666667vw;
    max-width: 157.8666666667vw;
    padding: 0;
    line-height: 18.6666666667vw;
    font-size: 10.4vw;
  }
}
.form-row.with-button .submit-btn.for-windows {
  padding-top: 0.5208333333vw;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form-row.with-button .submit-btn.for-windows {
    padding-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .form-row.with-button .submit-btn.for-windows {
    padding-top: 0;
  }
}
.form-row.with-button .submit-btn .arrow {
  width: 1.046875vw;
  margin-right: 0.5208333333vw;
}
@media only screen and (max-width: 767px) {
  .form-row.with-button .submit-btn .arrow {
    width: 5.36vw;
    margin-right: 2.6666666667vw;
  }
}
.form-row .fields-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media only screen and (max-width: 767px) {
  .form-row .fields-wrapper {
    display: flex;
    flex-direction: column;
  }
}
.form-row .field-wrapper {
  position: relative;
  width: 13.0208333333vw;
  margin-bottom: 2.5520833333vw;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form-row .field-wrapper {
    width: 27.8645833333vw;
    margin-bottom: 2.6666666667vw;
  }
}
@media only screen and (max-width: 767px) {
  .form-row .field-wrapper {
    width: 100%;
    margin-bottom: 19.7333333333vw;
  }
}
@media only screen and (max-width: 767px) {
  .form-row .field-wrapper:nth-child(3) {
    width: 100%;
  }
}
.form-row .field-wrapper.require {
  position: relative;
}
.form-row .field-wrapper.require::after {
  content: "*";
  position: absolute;
  top: -0.2604166667vw;
  right: -0.625vw;
  display: block;
  color: #000;
  font-size: 1.25vw;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form-row .field-wrapper.require::after {
    top: -0.2604166667vw;
    right: -0.5208333333vw;
    font-size: 1.4583333333vw;
  }
}
@media only screen and (max-width: 767px) {
  .form-row .field-wrapper.require::after {
    top: 0.5333333333vw;
    right: -3.7333333333vw;
    font-size: 9.0666666667vw;
  }
}
.form-row .field-wrapper.error label {
  border-bottom: 1px solid red !important;
}
.form-row .field-wrapper.error textarea {
  border: 1px solid red !important;
  border-bottom: none !important;
}
.form-row .field-wrapper.error :global .ant-select-selection {
  border-bottom: 0px solid red !important;
}
.form-row .field-wrapper .word-counter {
  font-size: 0.6770833333vw;
}
@media only screen and (max-width: 767px) {
  .form-row .field-wrapper .word-counter {
    font-size: 4.2666666667vw;
  }
}
.form-row .field-wrapper .opinion-length {
  position: absolute;
  min-width: 0.2604166667vw;
  padding-top: 0.2604166667vw;
  padding-bottom: 0.2604166667vw;
  font-size: 0.8333333333vw;
  line-height: 1.25vw;
  color: red;
  font-family: "NarkisBlock-Regular", sans-serif;
}
@media only screen and (max-width: 767px) {
  .form-row .field-wrapper .opinion-length {
    padding-top: 4vw;
    font-size: 7.2vw;
    line-height: 9.0666666667vw;
  }
}
.form-row .field-wrapper.with-phone input {
  padding-left: 0;
  direction: ltr;
}
.form-row .field-wrapper.short-field {
  width: 3.3854166667vw;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form-row .field-wrapper.short-field {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .form-row .field-wrapper.short-field {
    width: 23.2vw;
  }
}
.form-row .field-wrapper.long-field {
  width: 27.7604166667vw;
}
@media only screen and (max-width: 767px) {
  .form-row .field-wrapper.long-field {
    width: 100%;
  }
}
.form-row .field-wrapper.full-width-field {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .form-row .field-wrapper.full-width-field {
    padding-right: 0;
    padding-left: 1.6vw;
  }
}
.form-row .field-wrapper-second {
  margin-bottom: 1.0416666667vw;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form-row .field-wrapper-second {
    margin-bottom: 2.34375vw;
  }
}
.form-row .row-title {
  padding-bottom: 0.3125vw;
  font-size: 1.3020833333vw;
  line-height: 1.16;
  letter-spacing: 0.58px;
  font-family: "NarkisBlock-Medium", sans-serif;
  color: #000;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form-row .row-title {
    padding: 2.6666666667vw 0;
    font-size: 4.8vw;
  }
}
@media only screen and (max-width: 767px) {
  .form-row .row-title {
    padding-top: 2.1333333333vw;
    padding-bottom: 5.0666666667vw;
    font-size: 8.5333333333vw;
    line-height: 8.5333333333vw;
    letter-spacing: normal;
  }
}
.form-row .radio-btns-wrapper {
  display: flex;
  padding-bottom: 0.5208333333vw;
  flex-wrap: wrap;
}
@media only screen and (max-width: 767px) {
  .form-row .radio-btns-wrapper {
    padding-bottom: 4.4vw;
    flex-wrap: wrap;
  }
}
.form-row .radio-btns-wrapper .field-wrapper {
  width: auto;
  margin-bottom: 16px;
  margin-left: 1.09375vw;
}
@media only screen and (max-width: 767px) {
  .form-row .radio-btns-wrapper .field-wrapper {
    margin-bottom: 10.1333333333vw;
    margin-left: 8.2666666667vw;
  }
}
.form-row .radio-btns-wrapper .radio-btns-wrapper-title {
  width: 100%;
  padding-bottom: 0.1041666667vw;
  font-size: 0.9895833333vw;
  line-height: 1.1458333333vw;
  color: #000;
  font-family: "NarkisBlock-Medium", sans-serif;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form-row .radio-btns-wrapper .radio-btns-wrapper-title {
    padding-bottom: 0.5208333333vw;
    font-size: 1.1979166667vw;
    line-height: 1.1979166667vw;
  }
}
@media only screen and (max-width: 767px) {
  .form-row .radio-btns-wrapper .radio-btns-wrapper-title {
    padding-bottom: 5.3333333333vw;
    font-size: 6.6666666667vw;
    line-height: 7.7333333333vw;
    letter-spacing: 0.58px;
    font-family: "NarkisBlock-Medium", sans-serif;
  }
}
.form-row .radio-btns-wrapper .radio-btns-wrapper-title.error {
  color: red;
}
.form-row .radio-btns-wrapper.radio-btns-wrapper-with-title {
  padding-bottom: 0.9375vw;
  flex-wrap: wrap;
}
@media only screen and (max-width: 767px) {
  .form-row .radio-btns-wrapper.radio-btns-wrapper-with-title {
    padding-bottom: 4.5333333333vw;
    justify-content: start;
  }
}
@media only screen and (max-width: 767px) {
  .form-row .radio-btns-wrapper.radio-btns-wrapper-with-title .field-wrapper {
    margin-left: 9.0666666667vw;
  }
  .form-row .radio-btns-wrapper.radio-btns-wrapper-with-title .field-wrapper:last-child {
    margin-left: 0;
  }
}
.form-row .checkbox-wrapper {
  padding-top: 1.1979166667vw;
}
.form-row .checkbox-wrapper .error {
  color: red;
  font-size: 0.9375vw;
  font-family: "NarkisBlock-Regular", sans-serif;
}
@media only screen and (max-width: 767px) {
  .form-row .checkbox-wrapper .error {
    font-size: 7.2vw;
    line-height: 9.0666666667vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form-row .checkbox-wrapper {
    padding-top: 4.5333333333vw;
  }
}
@media only screen and (max-width: 767px) {
  .form-row .checkbox-wrapper {
    padding-top: 6.1333333333vw;
    padding-right: 0;
    padding-bottom: 4.8vw;
  }
}
@media only screen and (max-width: 767px) {
  .form-row .checkbox-wrapper span {
    font-size: 5.0666666667vw;
    letter-spacing: 0.44px;
    font-weight: normal;
  }
}
.form-row .checkbox-wrapper br {
  display: none;
}
@media only screen and (max-width: 767px) {
  .form-row .checkbox-wrapper br {
    display: block;
  }
}
.form-row .checkbox-wrapper .check-box-text-require {
  position: relative;
  padding-right: 1.25vw;
  margin-top: 0.8333333333vw;
  font-size: 0.78125vw;
  line-height: 1.17;
  color: #000;
  font-family: "NarkisBlock-Regular", sans-serif;
}
.form-row .checkbox-wrapper .check-box-text-require.error {
  border-bottom: 1px solid red;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form-row .checkbox-wrapper .check-box-text-require {
    font-size: 1.1979166667vw;
    line-height: 1.17;
  }
}
@media only screen and (max-width: 767px) {
  .form-row .checkbox-wrapper .check-box-text-require {
    padding-right: 10.6666666667vw;
    margin-top: 12.2666666667vw;
    font-size: 7.2vw;
    line-height: 1.33;
  }
}
.form-row .checkbox-wrapper .check-box-text-require::after {
  content: "*";
  position: absolute;
  top: -0.3645833333vw;
  right: 0.3645833333vw;
  font-size: 1.25vw;
}
@media only screen and (max-width: 767px) {
  .form-row .checkbox-wrapper .check-box-text-require::after {
    top: -3.2vw;
    right: 2.6666666667vw;
    font-size: 9.6vw;
  }
}

.file-placeholder {
  font-family: "opensanshebreew_regular", sans-serif;
}

.preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  width: 2.0833333333vw;
  height: 2.0833333333vw;
  transform: translate(-50%, -50%);
  animation-name: preloader-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .preloader {
    width: 6.9333333333vw;
    height: 6.9333333333vw;
  }
}
@media only screen and (max-width: 767px) {
  .preloader {
    width: 8vw;
    height: 8vw;
  }
}
.preloader circle {
  stroke: #fff;
  stroke-width: 5px;
  stroke-dasharray: 45, 45;
  stroke-dashoffset: 0;
}
@media only screen and (max-width: 767px) {
  .preloader circle {
    stroke-width: 7px;
  }
}

.charCounter {
  position: absolute;
  bottom: -1.0416666667vw;
  right: 0.2604166667vw;
  font-size: 0.78125vw;
}
@media only screen and (max-width: 767px) {
  .charCounter {
    font-size: 7.2vw;
    right: 2.1333333333vw;
    bottom: -9.3333333333vw;
  }
}

.red {
  color: red;
}

.flex-row {
  display: flex;
  column-gap: 1.71875vw;
}
@media only screen and (max-width: 767px) {
  .flex-row {
    flex-direction: column;
  }
}
.flex-row div {
  width: fit-content;
}

@keyframes preloader-animation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}